const content = {
  title: 'Betriebliche Altersvorsorge (bAV) - Pensionszusage',
  headvisualHeadline: 'Betriebliche Altersvorsorge (bAV) - Pensionszusage',
  start: {
    topline: 'Betriebliche Altersvorsorge',
    headline: 'Pensionszusage.',
    paragraphs: [
      'Die Pensions- oder Direktzusage ist die häufigste Form der betrieblichen Altersversorgung. Sie ist das Versprechen des Arbeitgebers an seinen Arbeitnehmer, bei Eintritt eines der drei biometrischen Risiken (Alter, Tod oder Invalidität) eine Leistung zu erbringen. Der Arbeitnehmer hat gegenüber seinem Arbeitgeber einen Rechtsanspruch auf die Versorgungsleistungen.',
      'Die Pensionszusage eignet sich aus steuerlichen Gründen nur für bilanzierende Unternehmen. Kleinbetriebe und Freiberufler können nicht von ihren Vorteilen profitieren. Zur Zielgruppe der Pensionszusage zählen insbesondere Besserverdiener (auch Gesellschafter-Geschäftsführer und Vorstände von Kapitalgesellschaften). Besonders attraktiv für diesen Personenkreis ist die unbegrenzte Versorgungshöhe (unter Berücksichtigung der Angemessenheit).'
    ]
  },
  text: {
    headline: 'Wie funktioniert die Pensionszusage?',
    paragraphs: [
      'Finanziert wird die Pensionszusage im Innenverhältnis durch die Bildung  von Pensionsrückstellungen. Im Versorgungsfall muss der Arbeitgeber  die notwendigen Mittel aufbringen und sie i.d.R. auch selbst an den  Versorgungsberechtigten auszahlen.',
      'Bei Eintritt vorzeitiger Versorgungsfälle (Invalidität und Tod) führt das  zu einer hohen finanziellen Belastung des Unternehmens. Um dies zu  vermeiden, kann eine Rückdeckungsversicherung abgeschlossen werden.  Mit ihr stehen im Versorgungsfall die notwendigen finanziellen Mittel zur Zahlung der Leistung zur Verfügung.',
      'Die Rückdeckungsversicherung wird vom Arbeitgeber (Versicherungsnehmer) auf das Leben des Arbeitnehmers (versicherte Person) abgeschlossen. Der Arbeitgeber ist alleiniger Bezugsberechtigter der Versicherung. Zur Sicherung der Ansprüche des Arbeitnehmers im Insolvenzfall des Arbeitgebers kann die Rückdeckungsversicherung an den Arbeitnehmer verpfändet werden.'
    ]
  },
  vorteileHeadline: 'Ihre Vorteile auf einen Blick',
  vorteile: [
    'Zugunsten einer beitragsorientierten Leistungszusage wird auf Gehaltsbestandteile, zum Beispiel Tantiemen, verzichtet',
    'Die Bonus-Vorsorge-Flex-Rente ist bilanzneutral',
    'Die Tantieme ist dabei in unbegrenzter Höhe steuerfrei',
    'Hilfestellung bei der rechtlichen Umsetzung über die Kooperationsanwälte der NÜRNBERGER möglich',
    'Da es sich um eine beitragsorientierte Leistungszusage handelt, hat das Unternehmen - anders als bei herkömmlichen Pensionszusagen - kaum Haftungsrisiko'
  ]
};

export default content;
