import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  ContentBox,
  List,
  Grid,
  NonStretchedImage,
  Paragraphs,
  HalvedContent
} from 'src/components';
import content from 'src/content/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-pensionszusage';
import subpageNavigations from 'src/data/subpage-navigations';

const BAVPensionszusagePage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: '75'
    }}
    subpageNavItems={subpageNavigations.betrieblicheAltersvorsorge}
    activeNavItem={3}
    useParentPathname
  >
    <section>
      <Grid>
        <HalvedContent swapped breakpoint="xl">
          <BackgroundImage fluid={data.oldMan.childImageSharp.fluid} />
          <ContentBox
            background="gray-blue"
            topline={content.start.topline}
            headline={content.start.headline}
            align="right"
          />
        </HalvedContent>

        <div className="children-margin">
          <Paragraphs items={content.start.paragraphs} />
        </div>
      </Grid>
    </section>
    <section>
      <div className="container container--simple children-margin">
        <h3>{content.text.headline}</h3>
        <Paragraphs items={content.text.paragraphs} />
        <NonStretchedImage fluid={data.illustration.childImageSharp.fluid} />
      </div>
      <ContentBox
        background="light-blue"
        headline={content.vorteileHeadline}
        single
      >
        <List items={content.vorteile} columns={2} />
      </ContentBox>
    </section>
  </MainLayout>
);

export default BAVPensionszusagePage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-wears-grey-jacket-and-looks-thoughtful.jpg" }
    ) {
      ...image
    }
    illustration: file(
      sourceInstanceName: { eq: "illustrations" }
      relativePath: { eq: "pensionszusage.jpg" }
    ) {
      ...image
    }
    oldMan: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-keeps-his-mouth-closed.jpg" }
    ) {
      ...image
    }
  }
`;
